@import "~@/erp/styles/variables/variables.scss";






















.link-standard {
  cursor: pointer;
  margin-right: 2%;
  .icon-shejiguifan {
    color: #1890ff;
  }
  .el-link {
    margin-left: 4px;
    text-decoration: underline;
    vertical-align: top;
  }
}
